import { PostTemplateProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx21WeekEmaChartTemplate from '@/templates/crypto/xxx-21-week-ema-chart';

export default function Ethereum21WeekEmaChart({
  location,
}: PostTemplateProps) {
  return (
    <Xxx21WeekEmaChartTemplate
      coinAbbreviation="ETH"
      coinFullName="Ethereum"
      token={Token.ETHUSDT}
      location={location}
      articleId={ArticleList.ETHEREUM_21_WEEK_EMA_CHART}
    ></Xxx21WeekEmaChartTemplate>
  );
}
