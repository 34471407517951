import { getKLines } from '@/binance';
import MaTable from '@/components/ma-table';
import PostTemplate from '@/templates/post';
import { DataFrame, defaultDataFrame, PostTemplateProps } from '@/types';
import { ema } from '@/utils/panda';
import { useState, useEffect } from 'react';
import { Articles } from '../../articles';
import { ChartLine } from '@/components/charts/ChartLine';
import CryptoPlot from '@/components/charts/crypto-plot';

export default function Xxx21WeekEmaChartTemplate({
  location,
  coinAbbreviation,
  coinFullName,
  token,
  articleId,
}: PostTemplateProps) {
  const [dataFrame, setDataFrame] = useState<DataFrame>(defaultDataFrame);
  const [ema21, setEma21] = useState<number[]>();
  const [ma21Line, setMa21Line] = useState<ChartLine>({
    y: [],
    color: 'blue',
    title: 'EMA21',
  });

  useEffect(() => {
    getKLines(token, '1w', 1000).then(function (df: DataFrame) {
      setDataFrame(df);
      const emaTmp = ema(df.close, 21);
      setEma21(emaTmp);

      setMa21Line({
        color: 'blue',
        title: 'EMA21',
        y: emaTmp,
      });
    });
  }, [token]);

  return (
    <PostTemplate
      location={location}
      keywords={[coinFullName.toLocaleLowerCase(), 'ema21', 'chart']}
      articleId={articleId}
    >
      <section>
        <p>
          Every time {coinAbbreviation} price went below this, closing 5 candles
          underneath it a pump to the upside has been seen which follows more
          than 60% correction.
        </p>
        <CryptoPlot
          data={dataFrame}
          lines={[ma21Line]}
          title={Articles[articleId].title}
          description={Articles[articleId].description}
        />
        <p>Last 21 Weeks of Exponential Moving Average for {coinFullName}:</p>
        <MaTable
          header1="Date"
          header2={'Closing Price (' + coinAbbreviation + ')'}
          header3={'21-Week EMA (' + coinAbbreviation + ')'}
          dates={dataFrame.date}
          close={dataFrame.close}
          ma={ema21}
          numberOfRows={21}
        />
      </section>
    </PostTemplate>
  );
}
